import q1 from './q1.svg'
import q2 from './q2.svg'
import q3 from './q3.svg'
import q4 from './q4.svg'
import q5 from './q5.svg'
import q6 from './q6.svg'
import q7 from './q7.svg'
import q8 from './q8.svg'
import q9 from './q9.svg'

export default [
  q1,
  q2,
  q3,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9,
];
