import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IoIosClose } from 'react-icons/io';

import withReducer from './hoc';
import img from './img';
import questions from './questions.json';

import Layout from '../Layout';

import Flex from '../../components/Flex';
import Box from '../../components/Box';
import Text from '../../components/Text';
import Container from '../../components/Container';
import VerticalCenter from '../../components/VerticalCenter';
import Button from '../../components/Button';
import BackgroundImage from '../../components/BackgroundImage';
import OverlayWithScroll from '../../components/OverlayWithScroll';
import Circle from '../../components/Circle';
import Link from '../../components/Link';
import MdBold from '../../components/MdBold';
import { mobileOrDesktop } from '../../components/ThemeProvider/theme';

class QuestionPage extends PureComponent {
  static getDerivedStateFromProps(nextProps) {
    const question = nextProps.pageContext;
    const pageId = question.id;
    const qId = question.id - 1;

    return {
      question,
      pageId,
      qId,
    }
  }

  state = {
  }

  componentDidMount() {
    this.props.startViewQuestion(this.state.qId);
  }

  handleClose = () => {
    this.setState({ showAnswer : false })
  }

  handleChoose = (value) => {
    const { id, correct } = this.state.question
    if (window.ga) {
      window.ga('send', 'event', '回答問題', `第${id}題`, `選項${value + 1}`);
    }
    this.setState({ select: value, chooseAnswer: true, showAnswer: true })
    this.props.setAnswer(id - 1, value, value === correct)
  }

  handleLearnMore = () => {
    const { qId } = this.state
    this.setState(({ showAnswer }) => ({ showAnswer: !showAnswer }))
    this.props.clickLearnMore(qId);
  }

  render() {
    const {
      showAnswer,
      select,
      question,
      chooseAnswer,
      qId,
      pageId,
    } = this.state;

    return (
      <Layout>
        <Container align="center" height="100vh" my={mobileOrDesktop('0', '0', '2em')}>
          <VerticalCenter>
            <Box height={mobileOrDesktop('50%', '11em')}>
              <Flex justifyContent="center" my="1em">
                {questions.map((q, index) => (
                  <Flex alignItems="center" key={index}>
                    <Circle width="1em" border="solid 1.5px" bg={index <= qId ? 'yellow' : 'gray'} />
                    {questions.length - 1 !== index && <Box bg="black" height="2px" px={mobileOrDesktop('0.75em', '1.5em', '0.5em')} />}
                  </Flex>
                ))}
              </Flex>
              <Box
                mt={mobileOrDesktop('3.5rem', '1.5em')}
                pl={mobileOrDesktop('0', '3em')}
                fontSize={mobileOrDesktop('1em', '1.5em')}
                position="relative"
                maxWidth={mobileOrDesktop('25em', questions[qId].textPerLine)}
                mx={mobileOrDesktop('2em', '0')}
              >
                <Circle
                  width="2.5em"
                  color="white"
                  bg="black"
                  position="absolute"
                  left={mobileOrDesktop('50%', '0.5em')}
                  top={mobileOrDesktop('-3em', 'auto')}
                  transform={mobileOrDesktop('translateX(-50%)', 'unset')}
                >
                  Q{pageId}
                </Circle>
                <Box textAlign="left">
                  <Text px="0.5em" fontWeight="bold" whiteSpace="pre-wrap">
                    {questions[qId].title}
                  </Text>
                  <Text px="0.75em" fontSize={mobileOrDesktop('auto', '1rem')} my="1em">{questions[qId].sub}</Text>
                </Box>
              </Box>
            </Box>
            <Box height="50%">
              <Flex
                flexDirection={mobileOrDesktop('column', 'row')}
                justifyContent="center"
                alignItems="center"
                my={mobileOrDesktop('0', '3.5em')}
              >
                <Box
                  width={1}
                  mb={mobileOrDesktop('1em', '0')}
                  position="relative"
                >
                  <BackgroundImage
                    src={img[qId]}
                    ratio={533.33 / 915.69}
                    borderRadius={mobileOrDesktop('4em', '5em')}
                    border="2px solid"
                  >
                    {showAnswer && (
                      <div>
                        <Box
                          position="absolute"
                          top="0"
                          bottom="0"
                          left="0"
                          right="0"
                          bg="rgba(255,255,255,0.9)"
                          px={mobileOrDesktop('2.5em', '3em')}
                          pt="3em"
                          pb={mobileOrDesktop('1.5em', '3em')}
                          borderRadius={mobileOrDesktop('4em', '5.5em')}
                        >
                          <OverlayWithScroll>
                            <MdBold>
                              {questions[qId].reserch}
                            </MdBold>
                            <Text mt="0.75em" fontSize="0.8em">{questions[qId].source}</Text>
                          </OverlayWithScroll>
                        </Box>
                          <Box position="absolute" top="-0.25em" right="1.25em">
                          <Button.transparent
                            border="transparent"
                            hoverBg="transparent"
                            color="black"
                            onClick={this.handleClose}
                          >
                            <IoIosClose size="2em" />
                          </Button.transparent>
                        </Box>
                      </div>
                    )}
                  </BackgroundImage>
                </Box>
                <Box ml="1em">
                  <Box m="0.5em">
                    {questions[qId].options.map((q, index) => (
                      <Box mb="0.75em" key={index}>
                        <Button
                          onClick={() => this.handleChoose(index)}
                          disabled={chooseAnswer}
                          selected={select === index}
                          correct={questions[qId].correct === index}
                          width={mobileOrDesktop('14.5em', '15em')}
                          py={mobileOrDesktop('0.5em', '0.75em')}
                          fontWeight="normal"
                        >
                          {q}
                        </Button>
                      </Box>
                    ))}
                    <Flex mt={mobileOrDesktop('1em', '2.5em')} justifyContent="center">
                      <Button
                        mx={mobileOrDesktop('0.25em', '0.5em')}
                        width={mobileOrDesktop('7em', '7em')}
                        py={mobileOrDesktop('0.5em', '0.75em')}
                        onClick={this.handleLearnMore}
                        fontWeight="normal"
                      >
                        數據解說
                      </Button>
                      <Button
                        mx={mobileOrDesktop('0.25em', '0.5em')}
                        width={mobileOrDesktop('7em', '7em')}
                        py={mobileOrDesktop('0.5em', '0.75em')}
                        is={Link}
                        to={question.isLast ? '/destination' : `/question/${pageId + 1}`}
                        fontWeight="normal"
                      >
                        {question.isLast ? '完成測驗' : '下一題'}
                      </Button>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </VerticalCenter>
        </Container>
      </Layout>
    );
  };
}

QuestionPage.propTypes = {
  pageContext: PropTypes.object,
  answers: PropTypes.array,
  setAnswer: PropTypes.func,
};

export default withReducer(QuestionPage);
