import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IoIosClose } from 'react-icons/io';
import { Scrollbars } from 'react-custom-scrollbars';
import { withContentRect } from 'react-measure';

import Box from './Box';
import Button from './Button';
import Text from './Text';
import { mobileOrDesktop } from './ThemeProvider/theme.js';


class OverlayWithScroll extends PureComponent {
  componentDidMount() {
    this.props.measure();
  }

  render() {
    const {
      measureRef,
      contentRect: { bounds: { height } },
      children,
    } = this.props;
    return (
      <Box height="100%">
        <Box height="100%" ref={measureRef}>
          <Scrollbars style={{ height }} autoHide>
            <Text textAlign="left" lineHeight="1.75" whiteSpace="pre-wrap">
              {children}
            </Text>
          </Scrollbars>
        </Box>
      </Box>
    );
  }
}

OverlayWithScroll.propTypes = {

};

export default withContentRect('bounds')(OverlayWithScroll);
